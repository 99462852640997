<template>
  <div id="company-view">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title> Empresa {{ companyData.name }} </v-card-title>
          <company-panel :company-data="companyData" @already-save="onAlreadySave"></company-panel>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { onUnmounted, ref } from 'vue'

import companyStoreModule from '../companyStoreModule'
import CompanyPanel from './company-panel/CompanyPanel.vue'

export default {
  components: {
    CompanyPanel,
  },
  setup() {
    const appCompany = 'app-company'

    // Register module
    if (!store.hasModule(appCompany)) store.registerModule(appCompany, companyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(appCompany)) store.unregisterModule(appCompany)
    })

    const companyData = ref({})

    const onAlreadySave = e => {
      alreadySave = e
    }

    if (router.currentRoute.params.id)
      store
        .dispatch(appCompany + '/fetchCompany', { id: router.currentRoute.params.id })
        .then(response => {
          companyData.value = response.data.company
        })
        .catch(error => {
          if (error.response.status === 400) {
            companyData.value = {}
          }
        })

    return {
      companyData,
      onAlreadySave,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/company.scss';
</style>
